const params = {
    debug: false,
    api_base_url: 'https://orange.igolife.net/api/v1/',
    api_test_url: 'https://orange-staging.igolife.net/api/v1/',
    api_base_url_v2: 'https://orange.igolife.net/api/v1/',
    api_test_url_v2: 'https://orange-staging.igolife.net/api/v1/',
    /*
    api_base_url: 'https://app.igolife.net/api/v1/',
    api_test_url: 'https://staging.igolife.net/api/v1/',
    api_base_url_v2: 'https://app.igolife.net/api/v1/',
    api_test_url_v2: 'https://staging.igolife.net/api/v1/',
    */
    h5_pro:'https://h5.igolife.net/',
    h5_test:'https://h5-staging.igolife.net/',
}
export {params}